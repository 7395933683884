import { useTranslation } from 'react-i18next'
import { IGloryNotifyInfos } from './graphql/type'
import { formatStrAddress } from '@/utils'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'

export default function BannerNotify({ list }: { list: IGloryNotifyInfos[] }) {
	const [emblaRef] = useEmblaCarousel({ loop: true, axis: 'y', watchDrag: false }, [Autoplay({ playOnInit: true, delay: 3000 })])

	const { t } = useTranslation()

	return (
		<div className="w-full overflow-hidden" ref={emblaRef}>
			<div className="flex h-[1.875rem] flex-col">
				{list.map((item, index) => {
					return (
						<div key={index} className="px-7 text-center text-xs leading-[1.875rem]">
							<span className="line-clamp-1 break-all">
								{item.level === 1 && t('banner.notify.title1', { msg: formatStrAddress(6, 4, item.address) })}
								{item.level === 2 && t('banner.notify.title2', { msg: formatStrAddress(6, 4, item.address) })}
								{item.level === 3 && t('banner.notify.title3', { msg: formatStrAddress(6, 4, item.address) })}
								{item.level === 4 && t('banner.notify.title4', { msg: formatStrAddress(6, 4, item.address) })}
								{item.level === 5 && t('banner.notify.title5', { msg: formatStrAddress(6, 4, item.address) })}
							</span>
						</div>
					)
				})}
			</div>
		</div>
	)
}
