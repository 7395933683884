import { ethers } from 'ethers'
import cnTw from './tw'

export const baseURL = ''
export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'dev'

export const graphURL =
	REACT_APP_ENV === 'dev'
		? 'https://api.studio.thegraph.com/query/88323/pota-protocol/version/latest'
		: 'https://api.studio.thegraph.com/query/94218/pota/version/latest'

export const formatStrAddress = (left: number, right: number, str: string) =>
	str.substring(0, left) + new Array(4).join('.') + str.substring(str.length - right, str.length)

/**
 * 验证签名及地址
 * @param {*} message 签名消息
 * @param {*} signerAddress 签名地址即用户钱包地址
 * @param {*} signature 签名后的字符串
 * @returns
 */
export const verifyMessage = async (message: string, signerAddress: string, signature: string) => {
	const recoveredAddress = ethers.verifyMessage(message, signature)

	return recoveredAddress === signerAddress
}

// 最小值
export const MIN_AMOUNT = 0.000001

// 数字千位分割，保留小数后 6位
export const numFormat = (num: number | string) =>
	Number(num) < MIN_AMOUNT
		? '0'
		: num
				.toString()
				.replace(/([0-9]+\.[0-9]{6})[0-9]*/, '$1')
				.replace(/\d+/, function (n) {
					// 先提取整数部分
					return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
						return $1 + ','
					})
				})

export const waitTime = (time: number = 100) => {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(true)
		}, time)
	})
}

/**
 * 数字处理小于0.000001时，显示0
 * @param num number 数字
 * @param isState "number" | "boollean" 返回类型 默认 数字
 */
export const numFormat2 = (num: number, isState?: 'number' | 'boollean') => {
	if (isState === 'boollean') {
		return Number(num) < MIN_AMOUNT ? false : true
	}
	return Number(num) < MIN_AMOUNT ? 0 : num
}

export { cnTw }
