import { getSubgraphsRequest } from '@/graphql'
import { IGloryNotifyInfos } from './type'

interface IGraphQlNotifyInfos {
	claimRewards: IGloryNotifyInfos[]
}

// 获取当前最新的10条通知信息
export const axiosGraphQlClaimRewardsData = async (): Promise<IGraphQlNotifyInfos> =>
	new Promise(async resolve => {
		let query = `
      query MyQuery {
  claimRewards(orderBy: createdAt, orderDirection: desc, first: 10) {
    address
    amount
    count
    createdAt
    id
    level
  }
}
    `
		try {
			const { data } = await getSubgraphsRequest({
				query: query,
			})
			resolve({
				claimRewards: data.claimRewards ?? [],
			})
		} catch (error) {
			resolve({
				claimRewards: [],
			})
		}
	})
