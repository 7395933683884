import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'
import { axiosGraphQlClaimRewardsData } from '../graphql'

export default function useNotifyHooks() {
	const { address } = useWeb3ModalAccount()

	const fetcher = async () => {
		return await axiosGraphQlClaimRewardsData().then(res => {
			const claimRewards = res.claimRewards
			return {
				claimRewards,
			}
		})
	}

	const { data, ...args } = useSWR(['useNotifyHooks', address], fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: true,
	})

	return {
		data: {
			claimRewards: data?.claimRewards || [],
		},
		...args,
	}
}
