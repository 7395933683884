import { ReactComponent as ICONBG1 } from '@/assets/home/card/icon-bg1.svg'
import { ReactComponent as ICONBG2 } from '@/assets/home/card/icon-bg2.svg'
import { ReactComponent as ICONBG3 } from '@/assets/home/card/icon-bg3.svg'
import { ReactComponent as ICONBG4 } from '@/assets/home/card/icon-bg4.svg'
import { ReactComponent as IMAGE_ICON1 } from '@/assets/home/image/icon1.svg'
import { ReactComponent as IMAGE_ICON2 } from '@/assets/home/image/icon2.svg'
import { ReactComponent as IMAGE_ICON3 } from '@/assets/home/image/icon3.svg'
import { ReactComponent as IMAGE_ICON4 } from '@/assets/home/image/icon4.svg'
import { DEFAULT_CHAINID } from '~/config/*'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'
import { multicall } from '@/lib/web3/multicall'
import { POTA, TOKEN } from '@/contract/abi'
import getChainConfig from '@/lib/web3/getChainConfig'
import { zeroAddress } from 'viem'

/**
 * @class IType 类型
 * @description 简单、普通、困难、挑战
 */
type IType = 'Simple' | 'Ordinary' | 'Difficulty' | 'Challenge'

type IBtnStatus = 'Invite' | 'Go'

/**
 * @class IHomeListInfo
 * @param {string} bgCard 背景图
 * @param {string} imageIcon 图标
 * @param {string} title 标题
 * @param {IBtnStatus} btnStatus 按钮状态
 * @param {boolean} isOpen 是否开启闯关
 */
export type IHomeListInfo = {
	bgCard: JSX.Element
	imageIcon: JSX.Element
	title: string
	btnStatus: IBtnStatus
	isOpen: boolean
	type: IType
}

const homeStyled = {
	card: 'absolute z-1',
}

const HomeListInfo: IHomeListInfo[] = [
	{
		bgCard: <ICONBG1 className={homeStyled.card} />,
		imageIcon: <IMAGE_ICON1 />,
		title: 'home.list.1',
		btnStatus: 'Go',
		isOpen: false,
		type: 'Simple',
	},
	{
		bgCard: <ICONBG2 className={homeStyled.card} />,
		imageIcon: <IMAGE_ICON2 />,
		title: 'home.list.2',
		btnStatus: 'Go',
		isOpen: false,
		type: 'Ordinary',
	},
	{
		bgCard: <ICONBG3 className={homeStyled.card} />,
		imageIcon: <IMAGE_ICON3 />,
		title: 'home.list.3',
		btnStatus: 'Go',
		isOpen: false,
		type: 'Difficulty',
	},
	{
		bgCard: <ICONBG4 className={homeStyled.card} />,
		imageIcon: <IMAGE_ICON4 />,
		title: 'home.list.4',
		btnStatus: 'Go',
		isOpen: false,
		type: 'Challenge',
	},
]

// 任务邀请进度标题文案
export enum TaskInviteTypeTitle {
	'KEY0' = 'home.info.1',
	'KEY1' = 'home.info.2',
	'KEY2' = 'home.info.3',
	'KEY3' = 'home.info.4',
	'KEY4' = 'home.info.5',
}

// 每层级用户邀请人数条件
export const TaskInviteNumberTotal = [
	[2, 4, 8, 16, 32],
	[4, 8, 16, 32, 64],
	[6, 12, 24, 48, 96],
]

export const HomeListColor: string[] = ['#4AFCDD', '#D677EE', '#EC863E', '#E13333']
export const HomeListBtnNoColor: string[] = ['#5D6A70', '#6D5D70', '#706B5D', '#705D5D']
export const HomeListBtnActiveClass: string[] = ['tail-btn-active1', 'tail-btn-active2', 'tail-btn-active3', 'tail-btn-active4']

type IUserInfo = {
	community: string
	inviter: string
	maxLevel: number
	exitCounts: number[]
	downLineCounts: number[][]
	depositMask: boolean[]
	gloryRewarded: boolean
}

const UserInfoInit: IUserInfo = {
	community: zeroAddress,
	inviter: zeroAddress,
	maxLevel: 0,
	exitCounts: [0, 0, 0, 0],
	downLineCounts: [
		[0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0],
		[0, 0, 0, 0, 0],
	],
	depositMask: [false, false, false, false],
	gloryRewarded: false,
}

// 验证器-下一层级是否开启
export const isNextLevelOpen = (downLineCounts: number[], index = 0) => {
	const totalArr = TaskInviteNumberTotal[index < 3 ? index : 2]
	return downLineCounts.every((ite, i) => ite >= totalArr[i])
}

// 解锁关卡判断
const isUnlockLevel = (level: number): number => {
	if (level <= 1) return 0
	else if (level <= 2) return 1
	else if (level <= 3) return 2
	else return 3
}

export default function useHomeHooks() {
	const { isConnected, address } = useWeb3ModalAccount()
	const { contracts } = getChainConfig()
	const POTA_ADDRESS = contracts['POTA_ADDRESS']

	const fetchUserInfo = async (): Promise<IUserInfo> => {
		if (!isConnected) return UserInfoInit
		return await multicall(DEFAULT_CHAINID, {
			contracts: [
				{
					address: POTA_ADDRESS,
					abi: POTA,
					functionName: 'getMemberInfo',
					args: [address],
				},
			],
		}).then(res => {
			if (res[0].status === 'success') {
				const object = res[0].result as any[]
				console.log('object', object)
				return {
					community: object[0] as string,
					inviter: object[1] as string,
					maxLevel: object[2] as number,
					exitCounts: object[3] as number[],
					downLineCounts: object[4] as number[][],
					depositMask: object[5] as boolean[],
					gloryRewarded: object[6] as boolean,
				}
			} else return UserInfoInit
		})
	}

	const fetcher = async () => {
		const userInfo = await fetchUserInfo()
		// 验证最小层级是否开启
		let isMinOpen = isConnected ? userInfo.depositMask.every(ite => !ite) : false
		console.log('isMinOpen', isMinOpen)
		const currentMaxOpen = isUnlockLevel(userInfo.maxLevel)
		return {
			userInfo,
			list: HomeListInfo.map((home, key) => {
				// 当前层级是否开启
				let isOpen = userInfo.depositMask[key]
				if (!isOpen && key === 0 && isMinOpen) isOpen = true
				return {
					...home,
					isOpen: key <= currentMaxOpen ? (!isConnected ? false : true) : false,
					btnStatus: isOpen && key === 0 && isMinOpen ? 'Go' : isOpen ? 'Invite' : 'Go',
				}
			}).map((item, key) => {
				return item
			}),
			isGloryReward: isConnected ? userInfo.depositMask.every(ite => ite) : false,
		}
	}
	const { data, ...args } = useSWR(isConnected ? `useHomeHooks-${address}` : 'useHomeHooks', fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	return {
		data: {
			list: (data?.list || HomeListInfo) as IHomeListInfo[],
			userInfo: data?.userInfo || UserInfoInit,
			isGloryReward: data?.isGloryReward || false, //是否可以开启啦荣耀殿堂
		},
		...args,
	}
}

// 查询用户的nonce
export async function handleQueryNonce({
	address,
	TOKEN_ADDRESS,
}: {
	address: string
	TOKEN_ADDRESS: `0x${string}`
}): Promise<bigint> {
	let nonces = BigInt(0)
	try {
		await multicall(DEFAULT_CHAINID, {
			contracts: [
				{
					address: TOKEN_ADDRESS,
					abi: TOKEN,
					functionName: 'nonces',
					args: [address],
				},
			],
		})
			.then(res => {
				return res[0].status === 'success' ? (res[0].result as bigint) : BigInt(0)
			})
			.then(res => {
				nonces = res
			})
	} catch (error) {}

	return nonces
}
