import React, { useMemo, useRef } from 'react'
import { useInfiniteScroll } from 'ahooks'
import { axiosGraphQlCommunitieInfosData } from '../graphql'
import BigNumber from 'bignumber.js'
import { ICommunitieInfos } from '../graphql/type'
import { formatStrAddress } from '@/utils'

const limit = 20

interface Result {
	list: ICommunitieInfos[]
	isMore: boolean
	page: number
}

function getLoadMoreList(isMore = true, page?: number): Promise<Result> {
	if (!isMore) {
		return new Promise(resolve => {
			resolve({ list: [], isMore: false, page: page || 0 })
		})
	}
	let pages = page || 0
	return new Promise(async resolve => {
		const { list, isMore } = await axiosGraphQlCommunitieInfosData({
			skip: new BigNumber(pages).times(limit).toNumber(),
		}).then(res => {
			console.log(res)

			return {
				list: res.communities,
				isMore: res.communities.length === 0 ? false : true,
			}
		})
		resolve({
			list,
			isMore,
			page: pages + 1,
		})
	})
}

export default function Communities() {
	const ref = useRef<HTMLDivElement>(null)

	const { data, loading, loadingMore, noMore } = useInfiniteScroll((d: any) => getLoadMoreList(d?.isMore, d?.page), {
		target: ref,
		isNoMore: d => {
			return d?.isMore === false
		},
	})

	const communities = useMemo(() => {
		if (!data) return []
		return data.list
	}, [data])

	return (
		<>
			<div className="mb-4 text-xl font-bold text-white">社區地址</div>
			<div ref={ref} className="z-0 h-[18.75rem] w-full overflow-scroll rounded-large bg-content1 p-4 shadow-small">
				<div className="mb-2 flex h-10 items-center rounded-lg bg-default-100 px-3 text-tiny font-semibold text-foreground-500">
					<span>地址</span>
				</div>
				{communities.map((community, index) => {
					return (
						<div key={index} className="flex h-9 items-center px-3 py-2 text-small font-normal text-[#ecedee]">
							{formatStrAddress(10, 10, community.id)}
						</div>
					)
				})}
				{!loading && noMore && communities.length > 50 && (
					<div className="flex h-9 items-center justify-center text-small font-normal text-white/50">沒有更多了</div>
				)}
				{(loadingMore || loading) && (
					<div className="flex h-9 items-center justify-center text-small font-normal text-white/50">載入中...</div>
				)}
			</div>
		</>
	)
}
