const banner = {
	'banner.swiper1.title': '歡迎進入<Text />波塔永續協議',

	'banner.swiper2.title': '還有機會瓜分高達',
	'banner.swiper2.tips': '空投獎勵！ ',
	'banner.swiper2.btn': '敬請期待',

	'banner.swiper3.title': '廣告聯盟',
	'banner.swiper3.tips': '廣告投放 <Text />需聯絡客服',

	'banner.notify.title1': '恭喜 {{msg}} 簡單難度闖關成功',
	'banner.notify.title2': '恭喜 {{msg}} 普通難度闖關成功',
	'banner.notify.title3': '恭喜 {{msg}} 困難難度闖關成功',
	'banner.notify.title4': '恭喜 {{msg}} 挑戰難度闖關成功',
	'banner.notify.title5': '恭喜 {{msg}} 榮耀殿堂闖關成功',
}
export default banner
