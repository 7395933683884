/* eslint-disable */
import React, { useEffect } from 'react'
import { Spinner } from '@nextui-org/react'
import { Link, Outlet, useLocation } from 'react-router-dom'

import TopBarModule from '@/components/TopBar'
import useWalletStore from '@/lib/store/useWalletStore'

import ConnectModale from '@/components/Connect'
import { isAddress } from 'viem'
import { useTranslation } from 'react-i18next'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

import LayoutBGModule from '@/components/Bg'
import useHomeBoss from '@/hooks/useHomeBoss'
import useHomeHooks from '@/hooks/useHomeHooks'
import useHomeRewardHooks from '@/hooks/useHomeRewardHooks'
import Banner from '@/components/Banner'

export default function LayoutPage() {
	const { t } = useTranslation()

	const location = useLocation()
	const { isConnected, address } = useWeb3ModalAccount()

	const isSign = useWalletStore(state => state.isSign)

	const { mutate: BossMutate } = useHomeBoss()
	const { mutate: HomeMutate } = useHomeHooks()

	const { mutate: RewardMutate } = useHomeRewardHooks({ level: 1 })

	useEffect(() => {
		if (isConnected && isAddress(address ?? '')) {
			HomeMutate()
			RewardMutate()
			BossMutate()
		}
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
	}, [address, isConnected])

	if (location.pathname === '/count')
		return (
			<div className="tail-layout-count relative flex flex-col !bg-color-bg-card">
				<ConnectModale isNoPage={true} />
				{isSign && (
					<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-2xl">
						<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
					</div>
				)}
				<Outlet />
			</div>
		)

	return (
		<div className="tail-layout relative flex flex-col !bg-color-bg-card">
			<LayoutBGModule />
			<TopBarModule />
			<Banner />
			{isSign && (
				<div className="absolute left-0 top-0 z-[60] flex h-full w-full items-center justify-center bg-white/5 backdrop-blur-[1px] sm:rounded-2xl">
					<Spinner label={t('connect.sign')} labelColor="warning" color="warning"></Spinner>
				</div>
			)}
			<div className="tail-bg-layout relative z-40 sm:h-[calc(100vh_-_3.875rem_-_4rem)]">
				<Outlet />
			</div>
		</div>
	)
}
