import { createPublicClient, http, PublicClient } from 'viem'
import { bsc as bscVime } from 'viem/chains'

const bsc = {
	...bscVime,
	rpcUrls: {
		default: {
			http: ['https://bsc-mainnet.infura.io/v3/c53807a18b034a10b43fc05a78bd3cad'],
		},
	},
}

export const SUPPER_CHAINS = [bsc]

export const SUPPER_NEXTWORK_NAME = {
	[bsc.id]: 'Bsc',
}

// Current Supper Chainds
export const CURRENT_SUPPER_CHAINDS = [bsc.id]

// 当前默认CHAINID
export const DEFAULT_CHAINID = CURRENT_SUPPER_CHAINDS[0]

export type ISwitchNetWrok = {
	chainId: `0x${string}`
	chainName: string
	rpcUrls: string[]
	nativeCurrency: any
	blockExplorerUrls: string[]
}

// 当前切换网络需要的参数
export const switchNetwork: {
	[x: number]: ISwitchNetWrok
} = {
	56: {
		chainId: `0x${bsc.id.toString(16)}`,
		chainName: bsc.name,
		rpcUrls: [bsc.rpcUrls.default.http[0]],
		nativeCurrency: bsc.nativeCurrency,
		blockExplorerUrls: ['https://bscscan.com'],
	},
}

export enum ContractName {
	TOKEN_ADDRESS = 'TOKEN_ADDRESS',
	POTA_ADDRESS = 'POTA_ADDRESS',
}

type IContractsConfig = {
	[x in ContractName]: `0x${string}`
}
type IOtherConfig = {
	symbol: string
	rpcUrl: string
	explorerUrl: string
}

export type Contracts = IContractsConfig & IOtherConfig

// 合约配置 & 其他信息配置
export const contracts: {
	[x: number]: Contracts
} = {
	56: {
		TOKEN_ADDRESS: '0x55d398326f99059fF775485246999027B3197955',
		POTA_ADDRESS: '0xf2F27055129Ff535F9E9DD2275556d91ba67aeb8',
		symbol: bsc.nativeCurrency.symbol,
		explorerUrl: 'https://bscscan.com',
		rpcUrl: bsc.rpcUrls.default.http[0],
	},
}

export const tokens = ['TOKEN_ADDRESS']

export const multicall3: {
	[x: number]: {
		publicClient: PublicClient
		multicallAddress: `0x${string}`
	}
} = {
	56: {
		publicClient: createPublicClient({
			chain: bsc,
			transport: http(),
		}),
		multicallAddress: '0xcA11bde05977b3631167028862bE2a173976CA11',
	},
}
