import { axiosGraphQlGloryRankingInfosData } from '@/pages/Home/graphql'
import { IGloryRankingInfos } from '@/pages/Home/graphql/type'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'
import { isAddress } from 'viem'

const InfoInit: IGloryRankingInfos = {
	id: '-1',
	address: '',
	createdAt: '',
	rank: '',
}

export default function useHomeBoss() {
	const { isConnected, address } = useWeb3ModalAccount()

	const fetcher = async () => {
		const { info, gloryCount } = await axiosGraphQlGloryRankingInfosData({
			address: address?.toLowerCase() ?? '',
		}).then(res => {
			const gloryRankingInfos = res.gloryRankingInfos,
				gloryCount = res.gloryCount
			return {
				info: gloryRankingInfos.length > 0 ? gloryRankingInfos[0] : null,
				gloryCount,
			}
		})
		return {
			info,
			gloryCount,
		}
	}

	const { data, ...args } = useSWR(isConnected ? `useHomeBoos-${address}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	return {
		isOpenBoss: isAddress(data?.info?.address ?? ''),
		bossInfo: data?.info ?? InfoInit,
		gloryCount: data?.gloryCount ?? 0,
		...args,
	}
}
