const ToastError = (error: any) => {
	let _error = ''
	try {
		_error = JSON.stringify(error)
	} catch (e) {
		_error = error.toString()
	}

	console.log('error', _error)

	if (_error.includes('Insufficient gas fee') || _error.includes('EstimateGasExecutionError')) {
		return 'Insufficient Gas Fees'
	} else if (_error.includes('user rejected action') || _error.includes('User denied transaction signature')) {
		return 'User Rejects Transaction'
	} else if (_error.includes('not find chainId')) {
		return 'Unsupported network'
	} else if (_error.includes('USDT insufficient')) {
		return 'USDTInsufficient'
	} else if (_error.includes('Unlock')) {
		return 'Wallet Locked'
	} else {
		return 'Transaction failed, Try again'
	}
}

export default ToastError
