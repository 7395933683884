import { Button, Modal, ModalBody, ModalContent, useDisclosure, Image } from '@nextui-org/react'
import type { ModalProps } from '@nextui-org/react'
import React, { forwardRef, useImperativeHandle } from 'react'

import { ReactComponent as BG_TASK } from '@/assets/modal-center-bg-task.svg'
import BIBI from '@/assets/bibi.png'
import { cnTw } from '@/utils'
import useHomeRewardHooks from '@/hooks/useHomeRewardHooks'
import useHomeHooks, { HomeListBtnActiveClass, HomeListBtnNoColor } from '@/hooks/useHomeHooks'
import { useTranslation } from 'react-i18next'

type Types = {
	placement?: ModalProps['placement']
	children: React.ReactNode
	title: string
	onDone: () => void
	loading: boolean
	level: number
}

function BaseModalCenter({ placement = 'center', children, title, loading, onDone, level }: Types, ref: any) {
	const { isOpen, onOpen, onClose, onOpenChange } = useDisclosure()
	const { t } = useTranslation()

	const { userInfo } = useHomeHooks().data

	const { rewardAmount, isRewardAmount } = useHomeRewardHooks({ level }).data
	const args = useHomeRewardHooks({ level })
	const rewardAmountLoading = args.isLoading || args.isValidating

	useImperativeHandle(ref, () => ({
		onOpen,
		onClose,
	}))

	return (
		<Modal
			isOpen={isOpen}
			placement={placement}
			onOpenChange={onOpenChange}
			hideCloseButton
			classNames={{
				base: ' max-w-[19.5rem] shadow-none rounded-[0.625rem] p-0 bg-transparent',
				backdrop: 'bg-black/60',
			}}
		>
			<ModalContent className="p-0">
				{onClose => (
					<>
						<ModalBody className="min-h-[27.6875rem] p-0">
							<BG_TASK className="absolute top-0 z-0 h-[calc(100%_-_0.625rem)] w-full" />
							<div className="relative z-10 flex h-full w-full flex-1 flex-col">
								<svg
									width="314"
									height="30"
									className="absolute w-full"
									viewBox="0 0 314 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M313 1V13.892L266.572 23.392L258.848 28.5H54.8421L47.0309 23.392L1 13.892V1H313Z"
										stroke="url(#paint0_linear_1100_726)"
										strokeWidth="1.44"
									/>
									<defs>
										<linearGradient id="paint0_linear_1100_726" x1="1" y1="10" x2="313" y2="10" gradientUnits="userSpaceOnUse">
											<stop stopColor="#C8C8C8" stopOpacity="0.01" />
											<stop offset="0.13118" stopColor="#00D4DF" />
											<stop offset="0.387912" stopColor="#00AB85" />
											<stop offset="0.492594" stopColor="#00F8C1" />
											<stop offset="0.604246" stopColor="#00AB85" />
											<stop offset="0.925427" stopColor="#00D4DF" />
											<stop offset="1" stopColor="#979797" stopOpacity="0.01" />
										</linearGradient>
									</defs>
								</svg>

								<div className="flex h-[1.875rem] items-center justify-center text-sm font-bold leading-5 text-[#4AFCDD]">
									{title}
								</div>
								{children}
								<div className="absolute bottom-0 z-50 flex w-full items-center justify-center">
									<Button
										isLoading={loading || rewardAmountLoading}
										onClick={onDone}
										isDisabled={!isRewardAmount}
										className={cnTw(
											'h-[3.75rem] min-w-[9.5625rem] gap-1 rounded-[0.3125rem]',
											isRewardAmount && HomeListBtnActiveClass[level - 1],
											'disabled:opacity-100',
										)}
										style={{
											background: isRewardAmount ? '' : HomeListBtnNoColor[level - 1],
										}}
									>
										{isRewardAmount && <Image src={BIBI} className="h-6 w-6 rounded-full" />}
										<span className="text-base font-bold text-black">{rewardAmount}U</span>
										<span className="text-sm text-[#333]">
											{t('home.modal.btn2')}({userInfo.exitCounts[level - 1]}/3)
										</span>
									</Button>
								</div>
							</div>
						</ModalBody>
					</>
				)}
			</ModalContent>
		</Modal>
	)
}

export default forwardRef(BaseModalCenter)
