import home from './zh/home'
import banner from './zh/banner'

const lang = {
	'app.switch.language.tips': '切換{{msg}}成功',
	'app.language': '語言',
	'app.loading': '載入中...',
	'connect.sign': '簽名...',
	'connect.btn': '連線',
	'connect.logout': '註銷',
	'connect.sign.error': '簽名驗證失敗',
	...home,
	...banner,
}

export default lang
