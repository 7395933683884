const home = {
	'home.check.point.title': '查看闖關進度',
	'home.btn.1': '邀請好友',
	'home.btn.2': '開始闖關',
	'home.btn.3': '授權',
	'home.modal.title1': '綁定邀請地址',
	'home.modal.title2': '闖關進度',
	'home.modal.btn1': '確認綁定',
	'home.modal.btn1_1': '已綁定',
	'home.modal.btn2': '領取獎勵',
	'home.placeholder.address': '請輸入綁定位址',

	'home.toast.copy.success': '邀請連結複製成功',
	'home.toast.balance.zero': '帳戶餘額為零',
	'home.toast.mix.amount': '最小闖關質訂金金額：{{amount}} U',
	'home.toast.sign.error': '簽名失敗',
	'home.toast.deposit.success': '网络激活成功',
	'home.toast.deposit.error': '質押失敗',
	'home.toast.no.bind.own': '不能綁定自己',
	'home.toast.no.bind.error': '已綁定地址',
	'home.toast.bind.success': '綁定成功',
	'home.toast.bind.error': '綁定失敗',
	'home.toast.claim.success': '領取成功',
	'home.toast.claim.error': '領取失敗',
	'home.toast.error.address': '請輸入正確的地址',
	'home.toast.error.members': '該帳號未啟動',
	'home.toast.approve.error': '授權失敗',
	'home.toast.approve.success': '授權成功',

	'home.boss.title': '榮耀殿堂',
	'home.boss.progress': '進度',
	'home.boss.tips': '自動領取',
	'home.boss.claimed': '（已發放）',
	'home.boss.nodata': '暫未進入榮耀殿堂',

	'home.list.1': '簡單難度',
	'home.list.2': '普通難度',
	'home.list.3': '困難難度',
	'home.list.4': '挑戰難度',
	'home.info.1': '一層數量',
	'home.info.2': '二層數量',
	'home.info.3': '三層數量',
	'home.info.4': '四層數量',
	'home.info.5': '五層數量',
}

export default home
