import { POTA } from '@/contract/abi'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import useWalletStore from '@/lib/store/useWalletStore'
import getChainConfig from '@/lib/web3/getChainConfig'
import { multicall } from '@/lib/web3/multicall'
import {
	Button,
	Input,
	Radio,
	RadioGroup,
	Table,
	TableBody,
	TableCell,
	TableColumn,
	TableHeader,
	TableRow,
} from '@nextui-org/react'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useState } from 'react'
import toast from 'react-hot-toast'
import useSWR from 'swr'
import { encodeFunctionData, isAddress } from 'viem'
import { DEFAULT_CHAINID } from '~/config/*'
import Communities from './components/Communities'
import { axiosGraphQlSystemData, InitCountInfo } from './graphql'

const CountPage = () => {
	const isSign = useWalletStore(state => state.isSign)
	const { isConnected, address } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()

	const { contracts } = getChainConfig()
	const POTA_ADDRESS = contracts['POTA_ADDRESS']

	const { onSendTransaction } = useSendTransaction()

	const [userAddress, setUserAddress] = useState('')
	const [type, setType] = useState<'true' | 'false'>('true')
	const [loading, setLoading] = useState(false)

	// 是否有权限设置
	const [hasPermission, setHasPermission] = useState(false)

	const { data } = useSWR(isConnected && !isSign && hasPermission ? `statistics_collection` : null, async () => {
		const info = await Promise.all([axiosGraphQlSystemData()]).then(res => {
			const { statistics } = res[0]

			return statistics
		})
		return {
			info,
		}
	})

	const countInfoData = data?.info || InitCountInfo

	useSWR(isConnected && !isSign ? `count/${address}` : null, async () => {
		const { isCheck } = await multicall(DEFAULT_CHAINID, {
			contracts: [
				{
					address: POTA_ADDRESS,
					abi: POTA,
					functionName: 'MANAGER_ROLE',
					args: [],
				},
			],
		}).then(async res => {
			const MANAGER_ROLE = res[0].result
			return await multicall(DEFAULT_CHAINID, {
				contracts: [
					{
						address: POTA_ADDRESS,
						abi: POTA,
						functionName: 'hasRole',
						args: [MANAGER_ROLE, address],
					},
				],
			}).then(res => {
				const hasRole = res[0].result as boolean
				return {
					isCheck: hasRole,
				}
			})
		})
		console.log('isCheck', isCheck)
		setHasPermission(isCheck)
	})

	const handleSubmit = async () => {
		setLoading(true)
		if (!isAddress(userAddress)) {
			toast.error('請輸入正確的使用者地址', { id: 'error' })
			setLoading(false)
			return
		}
		try {
			let abiData = [userAddress, type === 'true' ? true : false]
			console.log('abiData', abiData)
			const data = encodeFunctionData({
				abi: POTA,
				functionName: 'setCommunity',
				args: abiData,
			})
			await onSendTransaction({
				data: data,
				to: POTA_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			toast.success('設定成功', { id: 'depositSuccess' })
			setUserAddress('')
			setType('true')
			setLoading(false)
		} catch (error: any) {
			setLoading(false)
			toast.error(error.message || '設定失敗', { id: 'depositError' })
		}
	}

	if (!hasPermission)
		return (
			<div className="flex h-screen w-full items-center justify-center">
				{!isConnected && (
					<Button onClick={() => open()} className="h-11 w-4/5" color="primary">
						<span className="text-base font-bold">連接錢包</span>
					</Button>
				)}
				{isConnected && <span className="w-full text-center text-sm">目前帳號無權限</span>}
			</div>
		)

	return (
		<div className="flex flex-col gap-4 px-4">
			<div className="mb-4 text-xl font-bold text-white">設定社區</div>
			<Input placeholder="請輸入使用者地址" value={userAddress} onValueChange={setUserAddress} maxLength={42} />
			<RadioGroup
				label="選擇類型"
				defaultValue="true"
				orientation="horizontal"
				value={type}
				onValueChange={values => setType(values as 'true' | 'false')}
			>
				<Radio value="true">同意</Radio>
				<Radio value="false">取消</Radio>
			</RadioGroup>
			<Button isLoading={loading} onClick={handleSubmit} className="h-11 max-w-sm" color="primary">
				<span className="text-base font-bold">提交</span>
			</Button>

			<Communities />
			<div className="mb-4 text-xl font-bold text-white">數據統計</div>
			<Table>
				<TableHeader>
					<TableColumn>-</TableColumn>
					<TableColumn>激活</TableColumn>
					<TableColumn>領取</TableColumn>
				</TableHeader>
				<TableBody>
					<TableRow key="1">
						<TableCell>簡單</TableCell>
						<TableCell>{countInfoData.inLevel1}</TableCell>
						<TableCell>{countInfoData.outLevel1}</TableCell>
					</TableRow>
					<TableRow key="2">
						<TableCell>普通</TableCell>
						<TableCell>{countInfoData.inLevel2}</TableCell>
						<TableCell>{countInfoData.outLevel2}</TableCell>
					</TableRow>
					<TableRow key="3">
						<TableCell>困難</TableCell>
						<TableCell>{countInfoData.inLevel3}</TableCell>
						<TableCell>{countInfoData.outLevel3}</TableCell>
					</TableRow>
					<TableRow key="4">
						<TableCell>挑戰</TableCell>
						<TableCell>{countInfoData.inLevel4}</TableCell>
						<TableCell>{countInfoData.outLevel4}</TableCell>
					</TableRow>
					<TableRow key="5">
						<TableCell>榮耀</TableCell>
						<TableCell>{countInfoData.inLevel5}</TableCell>
						<TableCell>{countInfoData.outLevel5}</TableCell>
					</TableRow>
				</TableBody>
			</Table>
		</div>
	)
}

export default CountPage
