import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination, Autoplay } from 'swiper/modules'

import BG1 from '@/assets/swiper/bg1.png'
import BG2 from '@/assets/swiper/bg2.png'
import BG3 from '@/assets/swiper/bg3.png'
import { Trans, useTranslation } from 'react-i18next'
import { cnTw } from '@/utils'

import { ReactComponent as NOTIFY } from '@/assets/swiper/notify.svg'
import useNotifyHooks from './hooks/useNotifyHooks'
import BannerNotify from './Notify'

const swiperStyled = {
	base: 'relative z-10 flex h-full w-full items-start',
	content: 'flex h-full flex-col items-center pt-12 gap-1 pl-5',
	swipter: 'relative h-[13.25rem] w-full rounded-[0.625rem] bg-[#1E1E1E]',
}

export default function Banner() {
	const { t, i18n } = useTranslation()

	const { data } = useNotifyHooks()
	return (
		<div className="h-[21.25rem] px-4">
			<Swiper
				pagination={{
					dynamicBullets: true,
				}}
				loop={true}
				autoplay={{
					delay: 5000,
					disableOnInteraction: false,
				}}
				modules={[Pagination, Autoplay]}
				className="mySwiper"
			>
				<SwiperSlide>
					<div className={swiperStyled.swipter}>
						<img src={BG1} className="absolute left-0 top-0 h-full w-full rounded-[0.625rem] object-cover" alt="" />
						<div className={swiperStyled.base}>
							<div className="absolute left-3 top-1.5">
								<span className="text-sm text-[#606060]">POTA PROTOCOL</span>
							</div>
							<div className={swiperStyled.content}>
								<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M13 22.9667C12.5667 22.9667 11.9889 23.1111 11.5556 23.1111C5.2 23.1111 0 17.9111 0 11.5556C0 9.38889 0.577778 7.36667 1.73333 5.48889L2.02222 5.05555C2.88889 3.61111 4.04444 2.45556 5.48889 1.58889C6.93333 0.722222 8.52222 0.144444 10.2556 0H11.5556C17.9111 0 23.1111 5.2 23.1111 11.5556C23.1111 14.1556 22.2444 16.4667 20.9444 18.3444L20.8 18.4889C19.9333 19.6444 18.7778 20.6556 17.4778 21.5222C16.0333 22.3889 14.4444 22.8222 13 22.9667ZM11.4111 20.2222V17.3333L9.24444 14.1556L12.8556 10.5444L15.0222 13.1444H18.4889L19.6444 14.3C19.9333 13.4333 20.0778 12.5667 20.0778 11.5556C20.0778 6.78889 16.1778 2.88889 11.4111 2.88889C10.9778 2.88889 10.5444 2.88889 10.1111 3.03333L9.1 4.04444L10.9778 5.77778L11.5556 9.67778L8.66667 12.5667L3.75556 7.8C3.17778 8.95555 2.88889 10.1111 2.88889 11.5556C2.88889 16.3222 6.64444 20.2222 11.4111 20.2222Z"
										fill="#00D7A2"
									/>
								</svg>
								<div className="text-center text-xl font-bold text-white">
									<Trans
										i18nKey={'banner.swiper1.title'}
										components={{
											Text: <br />,
										}}
									/>
								</div>
								<div className="flex h-[1.125rem] min-w-[7.5rem] items-center justify-center rounded-full bg-[#09242D] px-[0.875rem]">
									<span className="text-[0.625rem] leading-[1.125rem] text-[#E7C491]">POTA PROTOCOL</span>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className={swiperStyled.swipter}>
						<img src={BG2} className="absolute left-0 top-0 h-full w-full rounded-[0.625rem] object-cover" alt="" />
						<div className={swiperStyled.base}>
							<div className={cnTw(swiperStyled.content, 'max-w-[12.5rem] items-start justify-center pt-0')}>
								{i18n.language === 'en' && <span className="text-xs text-[#E7C491]">Airdrop Announcement</span>}
								<div className="text-base text-white">{t('banner.swiper2.title')}</div>
								<div className="text-[2rem] font-bold text-[#E8C036]">$1,000,000</div>
								<div className="text-sm text-white">{t('banner.swiper2.tips')}</div>
								<div className="mt-1 flex h-[1.625rem] items-center justify-center rounded-[0.3125rem] border border-[#E7C491]">
									<span className="px-6 text-xs text-[#E7C491]">{t('banner.swiper2.btn')}</span>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
				<SwiperSlide>
					<div className={swiperStyled.swipter}>
						<img src={BG3} className="absolute left-0 top-0 h-full w-full rounded-[0.625rem] object-cover" alt="" />
						<div className={swiperStyled.base}>
							<div className="absolute left-3 top-3 flex h-[1.125rem] min-w-[7.5rem] items-center justify-center rounded-full bg-[#09242D] px-[0.875rem]">
								<span className="text-[0.625rem] leading-[1.125rem] text-[#4AFCDD]">POTA PROTOCOL</span>
							</div>
							<div className={cnTw(swiperStyled.content, 'max-w-[12.5rem] items-start justify-center pt-4')}>
								<div className="mb-2 text-xs text-white">{t('banner.swiper3.title')}</div>
								<div
									className={cnTw('text-xl font-bold !leading-[1.875rem] text-[#34FFC0]', i18n.language === 'en' && 'text-base')}
								>
									<Trans
										i18nKey={'banner.swiper3.tips'}
										components={{
											Text: <br />,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</SwiperSlide>
			</Swiper>
			{data.claimRewards && data.claimRewards.length > 0 && (
				<div className="relative z-10 my-2 h-[1.875rem] w-full rounded-full bg-[#0E383C]">
					<NOTIFY className="absolute left-2 top-2" />
					<BannerNotify list={data.claimRewards} />
				</div>
			)}
		</div>
	)
}
