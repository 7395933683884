import { getSubgraphsRequest } from '@/graphql'
import { IGloryRankingInfos } from './type'

interface IGraphQlGloryRankingInfos {
	gloryRankingInfos: IGloryRankingInfos[]
	gloryCount: number | string
}

// 获取当前用户进入的荣耀排行榜信息
export const axiosGraphQlGloryRankingInfosData = async ({ address }: { address: string }): Promise<IGraphQlGloryRankingInfos> =>
	new Promise(async resolve => {
		let query = `
      query MyQuery($address: Bytes) {
  gloryRankingInfos(where: {address: $address}) {
    address
    createdAt
    id
    rank
  }
	gloryCount(id: "1") {
    count
  }
}
    `
		try {
			const { data } = await getSubgraphsRequest({
				query: query,
				variables: {
					address: address,
				},
			})
			resolve({
				gloryRankingInfos: data.gloryRankingInfos ?? [],
				gloryCount: data.gloryCount.count ?? 0,
			})
		} catch (error) {
			resolve({
				gloryRankingInfos: [],
				gloryCount: 0,
			})
		}
	})
