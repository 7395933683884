'use client'
import { formatEther, formatUnits, parseAbi } from 'viem'
import useSWR from 'swr'

import getChainConfig from '../web3/getChainConfig'
import { multicall } from '../web3/multicall'
import { DEFAULT_CHAINID, tokens } from '~/config/*'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

let tokensFormat: {
	[x in string]: string
} = {}

// eslint-disable-next-line array-callback-return
tokens.map(item => {
	tokensFormat[item] = '0'
})

const useBalance = () => {
	const { address } = useWeb3ModalAccount()
	const chainId = DEFAULT_CHAINID

	const fetcher = async () => {
		if (typeof chainId === 'undefined') return
		const { multicall3, contracts } = getChainConfig(chainId)

		const { multicallAddress } = multicall3

		const abi = parseAbi([
			'function getBasefee() external view returns (uint256 basefee)',
			'function getEthBalance(address addr) view returns (uint256 balance)',
			'function balanceOf(address owner) view returns (uint256)',
			'function decimals() public view returns (uint8)',
		])

		const [main, basefee, ..._tokens] = await multicall(chainId, {
			contracts: [
				{
					address: multicallAddress,
					abi,
					functionName: 'getEthBalance',
					args: [address],
				},
				{
					address: multicallAddress,
					abi,
					functionName: 'getBasefee',
					args: [],
				},
				...tokens.map(item => {
					const contract = (contracts as any)[item]
					return {
						address: contract,
						abi,
						functionName: 'balanceOf',
						args: [address],
					}
				}),
				...tokens.map(item => {
					const contract = (contracts as any)[item]
					console.log('contract', contracts, 'item', item)
					return {
						address: contract,
						abi,
						functionName: 'decimals',
						args: [],
					}
				}),
			],
		})
		let tokensFormat: {
			[x in string]: string
		} = {}
		for (let index = 0; index < tokens.length; index++) {
			const name = tokens[index]
			const element = _tokens[index]
			const decimals = _tokens[index + tokens.length]
			tokensFormat[name] = formatUnits(BigInt(`${element.result || 0}`), parseInt(`${decimals.result || 0}`))
		}

		console.log('_tokens', _tokens)

		return {
			main: formatEther(BigInt(`${main.result || 0}`)),
			basefee: `${basefee.result || 0}`,
			tokens: tokensFormat,
		}
	}

	const { data, ...args } = useSWR(address && chainId ? `useBalance/${address}-${chainId}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	return {
		data: {
			main: data?.main || '0',
			basefee: data?.basefee || '0',
			tokens: data?.tokens || tokensFormat,
		},
		...args,
	}
}

export default useBalance
