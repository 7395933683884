import { getSubgraphsRequest } from '@/graphql'
import { ICommunitieInfos, ICountInfo } from './type'

interface IGraphQlCommunitieInfos {
	communities: ICommunitieInfos[]
}

interface IGraphQlCountInfos {
	statistics: ICountInfo
}

export const InitCountInfo: ICountInfo = {
	inLevel1: '0',
	inLevel2: '0',
	inLevel3: '0',
	outLevel5: '0',
	outLevel4: '0',
	outLevel3: '0',
	outLevel2: '0',
	outLevel1: '0',
	inLevel5: '0',
	inLevel4: '0',
}

// 获取当前项目对应的社区地址列表
export const axiosGraphQlCommunitieInfosData = async ({ skip }: { skip: number }): Promise<IGraphQlCommunitieInfos> =>
	new Promise(async resolve => {
		let query = `
      query MyQuery($skip: Int) {
  communities(where: {value: true}, skip: $skip) {
    id
    value
  }
}
    `
		try {
			const { data } = await getSubgraphsRequest({
				query: query,
				variables: {
					skip: skip,
				},
			})
			resolve({
				communities: data.communities ?? [],
			})
		} catch (error) {
			resolve({
				communities: [],
			})
		}
	})

// 获取系统统计数据
export const axiosGraphQlSystemData = async (): Promise<IGraphQlCountInfos> =>
	new Promise(async resolve => {
		let query = `
      query MyQuery {
  statistics(id: "1") {
    inLevel1
    inLevel2
    inLevel3
    outLevel5
    outLevel4
    outLevel3
    outLevel2
    outLevel1
    inLevel5
    inLevel4
  }
}
    `
		try {
			const { data } = await getSubgraphsRequest({
				query: query,
			})
			resolve({
				statistics: data.statistics || InitCountInfo,
			})
		} catch (error) {
			resolve({
				statistics: InitCountInfo,
			})
		}
	})
