import { ReactComponent as CARD1 } from '@/assets/home/boss-card.svg'
import { ReactComponent as CARD2 } from '@/assets/home/boss-card2.svg'

import { ReactComponent as LINE_BORDER } from '@/assets/home/boss/line-border.svg'
import { ReactComponent as LINE } from '@/assets/home/boss/line-new.svg'
import useHomeBoss from '@/hooks/useHomeBoss'
import BigNumber from 'bignumber.js'
import { useTranslation } from 'react-i18next'

import NODATA_BG from '@/assets/home/boss/bgs.png'
import { Image } from '@nextui-org/react'

export default function HomeBossModule() {
	const { t } = useTranslation()
	const { isOpenBoss, bossInfo, gloryCount } = useHomeBoss()

	const total = new BigNumber(32).plus(new BigNumber(bossInfo.rank).times(32)).toString()

	const bill = new BigNumber(gloryCount).div(total).div(2).plus(0.5).times(100).toFixed(0)

	return (
		<div className="relative flex h-[11.25rem] w-full justify-center">
			<CARD1 className="absolute z-0" />
			<div className="relative z-10 flex w-full flex-col items-center">
				<div className="mb-3 mt-1 text-xl font-bold leading-5 text-black">{t('home.boss.title')}</div>
				<CARD2 className="absolute top-9 z-0" />
				{!isOpenBoss && (
					<div className="relative z-10 flex w-full flex-col items-center justify-center px-[2.375rem] pt-1">
						<Image src={NODATA_BG} alt="" className="h-[6rem] w-[8rem]" />
						<div className="-mt-2 text-[0.625rem] leading-5 text-[#8A8A8A]">{t('home.boss.nodata')}</div>
					</div>
				)}
				{isOpenBoss && (
					<div className="relative z-10 w-full px-[2.375rem] text-start">
						{/* <div className="my-1 flex w-full items-center justify-center">
							<svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
								<g clipPath="url(#clip0_1112_1434)">
									<path
										d="M5.00977 0.324402C2.4209 0.324402 0.322266 2.42303 0.322266 5.0119C0.322266 7.60077 2.4209 9.6994 5.00977 9.6994C7.59863 9.6994 9.69727 7.60077 9.69727 5.0119C9.69727 2.42303 7.59863 0.324402 5.00977 0.324402ZM7.0498 6.69735C6.94189 6.88417 6.70303 6.94823 6.51621 6.84032L4.62988 5.75126C4.50234 5.67762 4.43203 5.54286 4.43467 5.40536C4.43467 5.4037 4.43457 5.40204 4.43457 5.40038V3.22216C4.43457 3.00643 4.60947 2.83153 4.8252 2.83153C5.04092 2.83153 5.21582 3.00643 5.21582 3.22216V5.18729L6.90693 6.16366C7.09365 6.27157 7.15772 6.51044 7.0498 6.69735Z"
										fill="white"
									/>
								</g>
								<defs>
									<clipPath id="clip0_1112_1434">
										<rect width="10" height="10" fill="white" />
									</clipPath>
								</defs>
							</svg>
							<div className="ml-1 text-[0.625rem] leading-5">
								{moment.unix(Number(bossInfo.createdAt)).format('YYYY-MM-DD HH:mm:ss')}
							</div>
						</div> */}
						<div className="mt-6 flex w-full items-center justify-center gap-4">
							<div className="tail-boss-progress min-w-[2.625rem] shrink-0 text-[0.625rem] text-[#333333]">
								{t('home.boss.progress')}
							</div>
							<div className="tail-boss-progress-bar relative w-full max-w-[9.5rem]">
								<LINE_BORDER className="absolute z-10 h-2 w-full" />
								<div className="absolute top-[1px] z-[5] flex w-[calc(100%_-_1px)] items-center overflow-hidden">
									{[1, 2, 3, 4, 5, 6, 1, 2, 3, 4, 5, 1, 2, 3, 4, 5, 7, 8, 9, 10, 1, 2, 3, 4, 5, 6, 5, 7, 8].map((i, key) => (
										<LINE key={key} />
									))}
								</div>

								<div
									className="tail-boss-progress-bar-active z-1 absolute top-[0.0625rem] h-1.5 shadow-sm"
									style={{
										width: Number(bill) > 100 ? 100 : bill + '%',
									}}
								></div>
							</div>
							<div className="flex shrink-0 items-center">
								<svg width="5" height="5" viewBox="0 0 5 5" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path fillRule="evenodd" clipRule="evenodd" d="M4.95152 0V4.94761H0V0H4.95152Z" fill="#DC9FCC" />
								</svg>
								<span className="ml-1 text-[0.625rem] font-bold leading-5">
									{/* {gloryCount}/{total} */}
									{Number(bill) > 100 ? 100 : bill}%
								</span>
							</div>
						</div>
						<div className="mx-1 mb-2 mt-3">
							<div className="tail-box-line2"></div>
						</div>
						<div className="flex w-full flex-col items-center">
							<span className="text-[0.625rem] leading-5">{t('home.boss.tips')}</span>
							<div className="flex items-center">
								<div className="tail-boss-amont-text text-sm font-bold">640000U</div>
								{gloryCount >= total && <div className="text-[0.625rem] text-[#8A8A8A]">{t('home.boss.claimed')}</div>}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
