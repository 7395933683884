import { MulticallParameters } from 'viem'
import getChainConfig from './getChainConfig'

export const multicall = async (chainId: number, args: MulticallParameters) => {
	const { multicall3 } = getChainConfig(chainId as any)
	const { publicClient } = multicall3
	return await publicClient.multicall(args)
}

export const waitForTransaction = async (chainId: number, hash: `0x${string}`) => {
	const { multicall3 } = getChainConfig(chainId as any)
	const { publicClient } = multicall3
	return await publicClient.waitForTransactionReceipt({
		hash,
	})
}

export const getGasPrice = async (chainId: number) => {
	const { multicall3 } = getChainConfig(chainId as any)
	const { publicClient } = multicall3
	return await publicClient.getGasPrice()
}

export const getBlockNumber = async (chainId: number) => {
	const { multicall3 } = getChainConfig(chainId as any)
	const { publicClient } = multicall3
	return await publicClient.getBlockNumber()
}

export const getBlockTimestamp = async (chainId: number) => {
	const { multicall3 } = getChainConfig(chainId as any)
	const { publicClient } = multicall3
	return (await publicClient.getBlock()).timestamp
}
