const banner = {
	'banner.swiper1.title': 'Welcome to the <Text />Potas Perpetual <Text />Protocol',

	'banner.swiper2.title': 'This has the opportunity to split up to',
	'banner.swiper2.tips': 'airdrop rewards!',
	'banner.swiper2.btn': 'Stay tuned',

	'banner.swiper3.title': 'Ad Network',
	'banner.swiper3.tips': 'Please contact <Text/> customer service <Text/> for ad placement.',

	'banner.notify.title1': 'Congratulations {{msg}} Simple Success',
	'banner.notify.title2': 'Congratulations {{msg}} Normal Success',
	'banner.notify.title3': 'Congratulations {{msg}} Difficulty Success',
	'banner.notify.title4': 'Congratulations {{msg}} Challenge Success',
	'banner.notify.title5': 'Congratulations {{msg}} Hall Of Glory Success',
}
export default banner
