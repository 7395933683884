import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { Button, Input, Modal, ModalBody, ModalContent } from '@nextui-org/react'
import React, { useEffect, useState } from 'react'

import { ReactComponent as BG } from '@/assets/modal-center-bg.svg'
import { cnTw, formatStrAddress } from '@/utils'
import useHomeHooks, { HomeListBtnActiveClass, HomeListBtnNoColor } from '@/hooks/useHomeHooks'
import { encodeFunctionData, isAddress, zeroAddress } from 'viem'
import { useTranslation } from 'react-i18next'
import useBalance from '@/lib/contract/useBalance'
import { useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers/react'
import getChainConfig from '@/lib/web3/getChainConfig'
import { useSendTransaction } from '@/lib/contract/useSendTransaction'
import toast from 'react-hot-toast'
import { POTA } from '@/contract/abi'
import { multicall } from '@/lib/web3/multicall'
import { DEFAULT_CHAINID } from '~/config/*'

type Props = {
	title?: string
	onDone: () => void
	addressInvite?: string
}

const BindModal = NiceModal.create<Props>(props => {
	const modal = useModal()
	const { t } = useTranslation()

	const [modalLoading, setModalLoading] = useState(false)
	const [inviterAddress, setInviterAddress] = useState(props?.addressInvite || '')
	const { main } = useBalance().data
	const { userInfo } = useHomeHooks().data

	const { isConnected, address } = useWeb3ModalAccount()
	const { open } = useWeb3Modal()
	const { contracts } = getChainConfig()
	const POTA_ADDRESS = contracts['POTA_ADDRESS']
	const { onSendTransaction } = useSendTransaction()
	const { mutate: mutateBalance } = useBalance()
	const { mutate: mutateHome } = useHomeHooks()

	useEffect(() => {
		if (props.addressInvite) {
			setInviterAddress(props.addressInvite)
		}
	}, [props.addressInvite])

	// 验证是否可以绑定
	const handleMembers = async (inviter: string): Promise<boolean> => {
		try {
			const depositMask = await multicall(DEFAULT_CHAINID, {
				contracts: [
					{
						address: POTA_ADDRESS,
						abi: POTA,
						functionName: 'members',
						args: [inviter],
					},
				],
			}).then(res => {
				if (res[0].status === 'success') {
					console.log('res', res[0].result)
					let object = res[0].result as any[]
					return object[3]
				} else return 0
			})
			return Number(depositMask) > 0
		} catch (error) {
			return false
		}
	}

	const handleBindInvite = async () => {
		if (!isConnected || !address) {
			open()
			return
		}
		setModalLoading(true)
		if (!isAddress(inviterAddress)) {
			toast.error(t('home.toast.error.address'), { id: 'bindError' })
			setModalLoading(false)
			return
		}
		const isMembers = await handleMembers(inviterAddress)
		if (!isMembers) {
			setModalLoading(false)
			toast.error(t('home.toast.error.members'), { id: 'bindError' })
			return
		}
		if (inviterAddress.toString() === address.toString()) {
			toast.error(t('home.toast.no.bind.own'), { id: 'bindError' })
			setModalLoading(false)
			modal.remove()
			props.onDone()
			return
		}
		if (Number(main) === 0) {
			toast.error(t('home.toast.balance.zero'), { id: 'bindError' })
			setModalLoading(false)
			return
		}
		if (userInfo.inviter !== zeroAddress) {
			toast.error(t('home.toast.no.bind.error'), { id: 'bindError' })
			modal.remove()
			props.onDone()
			return
		}
		try {
			let abiData = [inviterAddress]
			const data = encodeFunctionData({
				abi: POTA,
				functionName: 'register',
				args: abiData,
			})
			await onSendTransaction({
				data: data,
				to: POTA_ADDRESS,
				onTransactionHash: (hash: string) => {
					console.log('Transaction hash:', hash)
				},
			})
			toast.success(t('home.toast.bind.success'), { id: 'bindSuccess' })
			props.onDone()
			mutateBalance()
			mutateHome()
			setModalLoading(false)
			modal.remove()
		} catch (error: any) {
			toast.error(error.message || error || t('home.toast.bind.error'), { id: 'bindError' })
			setModalLoading(false)
		}
	}

	return (
		<Modal
			isOpen={modal.visible}
			placement="center"
			onOpenChange={modal.remove}
			hideCloseButton
			classNames={{
				base: 'max-w-[19.5rem] -mt-[60%] shadow-none rounded-[0.625rem] p-0 bg-transparent',
				backdrop: 'bg-black/60',
			}}
		>
			<ModalContent className="p-0">
				{onClose => (
					<>
						<ModalBody className="min-h-[13rem] p-0">
							<BG className="absolute top-0 z-0 h-[calc(100%_-_0.625rem)] w-full" />
							<div className="relative z-10 flex h-full w-full flex-1 flex-col">
								<svg
									width="314"
									height="30"
									className="absolute w-full"
									viewBox="0 0 314 30"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										fillRule="evenodd"
										clipRule="evenodd"
										d="M313 1V13.892L266.572 23.392L258.848 28.5H54.8421L47.0309 23.392L1 13.892V1H313Z"
										stroke="url(#paint0_linear_1100_726)"
										strokeWidth="1.44"
									/>
									<defs>
										<linearGradient id="paint0_linear_1100_726" x1="1" y1="10" x2="313" y2="10" gradientUnits="userSpaceOnUse">
											<stop stopColor="#C8C8C8" stopOpacity="0.01" />
											<stop offset="0.13118" stopColor="#00D4DF" />
											<stop offset="0.387912" stopColor="#00AB85" />
											<stop offset="0.492594" stopColor="#00F8C1" />
											<stop offset="0.604246" stopColor="#00AB85" />
											<stop offset="0.925427" stopColor="#00D4DF" />
											<stop offset="1" stopColor="#979797" stopOpacity="0.01" />
										</linearGradient>
									</defs>
								</svg>

								<div className="flex h-[1.875rem] items-center justify-center text-sm font-bold leading-5 text-[#4AFCDD]">
									{t('home.modal.title1')}
								</div>
								<div className="relative z-20 flex flex-1 flex-col items-center justify-center px-3">
									<Input
										classNames={{
											inputWrapper: 'rounded-[0.1875rem] text-center bg-[#092F30] outline outline-1 outline-[#0B818B]',
											input: 'text-center !text-[#4AFCDD]',
										}}
										placeholder={t('home.placeholder.address')}
										className="-mt-4 h-11 !opacity-100"
										value={userInfo.inviter !== zeroAddress ? formatStrAddress(6, 4, userInfo.inviter) : inviterAddress}
										isDisabled={userInfo.inviter !== zeroAddress}
										onValueChange={setInviterAddress}
									/>
								</div>
								<div className="absolute bottom-0 z-50 flex w-full items-center justify-center">
									<Button
										isLoading={modalLoading}
										onClick={handleBindInvite}
										isDisabled={userInfo.inviter !== zeroAddress}
										className={cnTw(
											'h-10 min-w-[9.5625rem] rounded-[0.3125rem]',
											HomeListBtnActiveClass[0],
											'disabled:opacity-100',
										)}
										style={{
											background: userInfo.inviter === zeroAddress ? '' : HomeListBtnNoColor[0],
										}}
									>
										<span className="text-base font-bold text-[#333]">
											{userInfo.inviter !== zeroAddress ? t('home.modal.btn1_1') : t('home.modal.btn1')}
										</span>
									</Button>
								</div>
							</div>
						</ModalBody>
					</>
				)}
			</ModalContent>
		</Modal>
	)
})

export const bindModal = (props: Props) => {
	NiceModal.show(BindModal, props)
}

export default BindModal
