import home from './en/home'
import banner from './en/banner'

const lang = {
	'app.switch.language.tips': 'Switch {{msg}} Success',
	'app.language': 'Language',
	'app.loading': 'loading...',
	'app.address.error.tips': 'Address Error',

	'connect.sign': 'Sign...',
	'connect.btn': 'Connect',
	'connect.logout': 'logout',
	'connect.sign.error': 'Signature verification failed',
	...home,
	...banner,
}

export default lang
