import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { NextUIProvider } from '@nextui-org/react'
import { Toaster } from 'react-hot-toast'
import NiceModal from '@ebay/nice-modal-react'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<React.StrictMode>
		<NextUIProvider>
			<NiceModal.Provider>
				<App />
				<Toaster
					position="top-center"
					toastOptions={{
						style: {
							background: '#151C1E',
							color: 'white',
							fontSize: '14px',
						},
						duration: 2000,
					}}
				/>
			</NiceModal.Provider>
		</NextUIProvider>
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
