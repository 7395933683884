import { POTA } from '@/contract/abi'
import getChainConfig from '@/lib/web3/getChainConfig'
import { multicall } from '@/lib/web3/multicall'
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import useSWR from 'swr'
import { formatEther } from 'viem'
import { DEFAULT_CHAINID } from '~/config/*'
import useHomeHooks, { isNextLevelOpen } from './useHomeHooks'
import { useMemo } from 'react'

export default function useHomeRewardHooks({ level }: { level: number }) {
	const { isConnected, address } = useWeb3ModalAccount()
	const { contracts } = getChainConfig()
	const POTA_ADDRESS = contracts['POTA_ADDRESS']

	const { userInfo } = useHomeHooks().data

	const fetcher = async () => {
		return await multicall(DEFAULT_CHAINID, {
			contracts: [
				{
					address: POTA_ADDRESS,
					abi: POTA,
					functionName: 'calculateReward',
					args: [level],
				},
			],
		}).then(res => {
			return res[0].status === 'success' ? formatEther(BigInt(`${res[0].result || 0}`)) : 0
		})
	}

	const { data, ...args } = useSWR(isConnected ? `useHomeRewardHooks-${address}-${level}` : null, fetcher, {
		revalidateIfStale: false,
		revalidateOnFocus: false,
	})

	const isRewardAmount = useMemo(() => {
		if (!isConnected) return false
		const currentDownLineCounts = userInfo.downLineCounts[level - 1]
		return isNextLevelOpen(currentDownLineCounts, userInfo.exitCounts[level - 1])
	}, [isConnected, level, userInfo.downLineCounts, userInfo.exitCounts])

	return {
		data: {
			rewardAmount: Number(data) || 0,
			isRewardAmount,
		},
		...args,
	}
}
